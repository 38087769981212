import React from "react"
import { isEmpty } from "lodash"
import Layout from "../../components/layout"
// import SingleProduct from "../../components/single-product";
import SEO from "../../components/seo"
import { getOgImage } from "../../utils/functions"
import CategoryContent from "../../components/category/CategoryContent"

const ProductsPage = props => {
  const {
    pageContext: {
      title,
      seo,
      uri,
      content,
      id,
      acfProduct,
      options,
      products,
    },
  } = props
  const { code: locale } = props.pageContext.wpmlTranslated.current[0]
  const { node: translated } = props.pageContext.wpmlTranslated
  const lang = locale.substring(0, 2)
  return (
    <Layout lang={lang} page="product" translated={translated}>
      {!isEmpty(props.pageContext) ? (
        <>
          <SEO
            title={title}
            translated={translated}
            seoData={seo}
            uri={uri}
            lang={lang}
            page={"product"}
            // header={ siteTitle: 'Gatsby WooCommerce Theme' }}
            openGraphImage={getOgImage(seo)}
          />
          <CategoryContent
            id={id}
            lang={lang}
            options={options.template.productCategory}
            productBoxButton={acfProduct.buttonTextProduct}
            breadcrumbs={acfProduct.breadcrumbs}
            products={products}
            title={title}
            translated={translated}
            description={content}
          />
        </>
      ) : (
        <div>Something went wrong</div>
      )}
    </Layout>
  )
}
export default ProductsPage
